import { hot } from 'react-hot-loader/root'
import React from 'react'
import { ServerLocation, Router } from '@reach/router'
import styled from 'styled-components'
import { Provider } from 'react-redux'

import PageSetup from './PageSetup'

import 'shared/lib/polyfills'
import store from 'marketing/redux/store'
import Normalize from 'marketing/components/Global/normalize'
import GlobalStyles from 'marketing/components/Global/global'
import useGoogleAnalytics from 'shared/lib/useGoogleAnalytics'
import useScrollToTop from 'shared/lib/useScrollToTop'
import GoSolar from 'marketing/pages/GoSolar/GoSolar'
import Careers from 'marketing/pages/Careers/Careers'
import OpenRoles from 'marketing/pages/Careers/OpenRoles/OpenRoles'
import JobApplication from 'marketing/pages/Careers/JobApplication/JobApplication'
import Contact from 'marketing/pages/Contact/Contact'
import Login from 'marketing/pages/Auth/Login/Login'
import ResetPassword from 'marketing/pages/Auth/ResetPassword/ResetPassword'
import ResetPasswordForm from 'marketing/pages/Auth/ResetPassword/ResetPasswordForm'
import ResetPasswordSuccess from 'marketing/pages/Auth/ResetPassword/ResetPasswordSuccess'
import ChangePassword from 'marketing/pages/Auth/ChangePassword/ChangePassword'
import MultiFactorAuthentication from 'marketing/pages/Auth/MultiFactorAuthentication/MultiFactorAuthentication'
import About from 'marketing/pages/About/About'
import Privacy from 'marketing/pages/Legal/Privacy'
import Terms from 'marketing/pages/Legal/Terms'
import ProviderTerms from 'marketing/pages/Legal/ProviderTerms'
import Sustainability from 'marketing/pages/Sustainability/Sustainability'
import Press from 'marketing/pages/Press/Press'
import Invitation from 'marketing/pages/Signup/Invitation/Invitation'
import InvitationInvalid from 'marketing/pages/Signup/Invitation/InvitationInvalid'
import Signup from 'marketing/pages/Signup/Signup/Signup'
import RequestInfoSuccess from 'marketing/pages/RequestInfoSuccess/RequestInfoSuccess'

interface Props {
  url: string
  flash?: {
    message?: string
    level?: string
  }
}

const MarketingStyled = styled.div({
  overflow: 'hidden',
})

const Switchboard = (): JSX.Element => {
  return (
    <Router primary={false}>
      <GoSolar path="/">
        <RequestInfoSuccess path="success" />
      </GoSolar>

      <Login path="login" />
      <ResetPassword path="reset-password">
        <ResetPasswordForm path="/" />
        <ResetPasswordSuccess path="success" />
      </ResetPassword>
      <ChangePassword path="change-password" />
      <MultiFactorAuthentication path="mfa" />

      <Signup path="signup" />
      <Invitation path="user/invitation/accept" />
      <InvitationInvalid path="user/invitation/invalid" />

      <About path="about" />

      <Careers path="careers" />
      <OpenRoles path="careers/open-roles" />
      <OpenRoles path="careers/open-roles/department/:departmentId" />
      <JobApplication path="careers/apply/:jobId" />

      <Contact path="contact" />
      <Press path="press" />
      <Sustainability path="sustainability" />

      <Privacy path="legal/privacy" />
      <Terms path="legal/terms" />
      <ProviderTerms path="legal/provider-terms" />
    </Router>
  )
}

const Marketing = (props: Props): JSX.Element => {
  useGoogleAnalytics(process.env.GOOGLE_ANALYTICS)
  useScrollToTop()

  return (
    <Provider store={store}>
      <PageSetup flash={props.flash} />
      <MarketingStyled>
        <Normalize />
        <GlobalStyles />

        {typeof document === 'undefined' ? ( // For SSR
          <ServerLocation url={props.url}>
            <Switchboard />
          </ServerLocation>
        ) : (
          <Switchboard />
        )}
      </MarketingStyled>
    </Provider>
  )
}

export default hot(Marketing)
