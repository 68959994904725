import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { navigate } from '@reach/router'

import { resetPasswordPath, mfaPath, afterSignInPath } from 'marketing/helpers/routes'
import Section from 'marketing/components/Section/Section'
import Anchor from 'marketing/components/Anchor/Anchor'
import Container from 'marketing/components/Container/Container'
import FormWrapper from 'shared/components/Form/FormWrapper/FormWrapper'
import Email from 'shared/components/Form/Email/Email'
import Password from 'shared/components/Form/Password/Password'
import Button from 'marketing/components/Button/Button'
import { RootState } from 'marketing/redux/store'
import { selectIsLoading } from 'shared/redux/loading'
import { isSuccess } from 'shared/lib/utils'
import { logIn, LOG_IN } from 'shared/redux/user/effects'
import { setErrors } from 'shared/lib/formUtils'
import { UserInterface } from 'shared/redux/user/interfaces'
import { selectUser } from 'shared/redux/user/selectors'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  user?: UserInterface
  isLoading?: boolean
  dispatch?: AppDispatch
}

const Text = styled.p({
  textAlign: 'center',
})

const LoginForm = (props: Props): JSX.Element => {
  // If the user is already logged in, redirect to / so the server can figure out which portal to send them to
  useEffect((): void => {
    if (props.user?.id) window.location.assign('/')
  }, [props.user])

  const handleSubmit = (values, form): void => {
    props.dispatch(logIn(values)).then((action): void => {
      if (isSuccess(action)) {
        // If MFA is enabled, navigate to the MFA form. Otherwise, hard redirect to the user's portal
        if (action.payload.mfaEnabled) navigate(mfaPath(), { state: { user: action.payload } })
        else window.location.assign(afterSignInPath())
      } else {
        setErrors(form, { base: action.payload.error })
      }
    })
  }

  return (
    <Section h1="Log In" data-section="login-form">
      <Container maxWidth={400}>
        <Formik initialValues={{ email: '', password: '', rememberMe: true }} onSubmit={handleSubmit}>
          <FormWrapper method="post">
            <Email name="email" label="Email Address" autoFocus />
            <Password name="password" />

            <Button type="submit" block disabled={props.isLoading}>
              Submit
            </Button>
          </FormWrapper>
        </Formik>
        <Text>
          <Anchor to={resetPasswordPath()} isAlwaysColored underline>
            Forgot Password?
          </Anchor>
        </Text>
      </Container>
    </Section>
  )
}

const mapState = (state: RootState): Props => ({
  user: selectUser(state),
  isLoading: selectIsLoading(state, [LOG_IN]),
})

export default connect(mapState)(LoginForm)
