import React from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { RouteComponentProps } from '@reach/router'

import { resetPasswordSuccessPath } from 'marketing/helpers/routes'
import Section from 'marketing/components/Section/Section'
import Container from 'marketing/components/Container/Container'
import FormWrapper from 'shared/components/Form/FormWrapper/FormWrapper'
import Email from 'shared/components/Form/Email/Email'
import Button from 'marketing/components/Button/Button'
import { RootState } from 'marketing/redux/store'
import { selectIsLoading } from 'shared/redux/loading'
import { isSuccess } from 'shared/lib/utils'
import { setErrors } from 'shared/lib/formUtils'
import { resetPassword, RESET_PASSWORD } from 'shared/redux/user/effects'
import { AppDispatch } from 'shared/redux/types'

interface Props {
  isLoading?: boolean
  dispatch?: AppDispatch
}

const ResetPasswordForm = (props: Props & RouteComponentProps): JSX.Element => {
  const handleSubmit = (values, form): void => {
    props.dispatch(resetPassword(values)).then((action): void => {
      isSuccess(action) ? props.navigate(resetPasswordSuccessPath()) : setErrors(form, { base: action.payload.error })
    })
  }

  return (
    <Section
      h1="Reset Password"
      text="Enter the email address that you used to sign up for Wunder and we'll send you an email with a link to reset your password."
      data-section="reset-password-form"
    >
      <Container maxWidth={400}>
        <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
          <FormWrapper>
            <Email name="email" />

            <Button type="submit" block disabled={props.isLoading}>
              Submit
            </Button>
          </FormWrapper>
        </Formik>
      </Container>
    </Section>
  )
}

const mapState = (state: RootState): Props => ({
  isLoading: selectIsLoading(state, [RESET_PASSWORD]),
})

export default connect(mapState)(ResetPasswordForm)
