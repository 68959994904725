import React from 'react'

import ProjectedSavings from './assets/projected-savings.png'
import ProjectedSavings2x from './assets/projected-savings@2x.png'

import Section from 'marketing/components/Section/Section'
import SideBySide from 'marketing/components/SideBySide/SideBySide'
import Container from 'marketing/components/Container/Container'

const SaveOnElectricity = (): JSX.Element => {
  return (
    <Section
      h2="Save Up To 20% On Electricity"
      text="Whether you’re trying to reduce your own operating expenses or unlock new benefits for your tenants, integrating solar enhances the value of your real estate assets. A new solar system can now generate $100,000s in cashflow for you over its lifetime."
    >
      <div>
        <Container>
          <SideBySide>
            <img src={ProjectedSavings} srcSet={ProjectedSavings + ' 1x,' + ProjectedSavings2x + ' 2x'} />
            <>
              <div>
                <h5>Save Money Day 1</h5>
                <p>
                  By installing a solar system on your roof or parking lot, you can start saving money immediately,
                  relative to what you would have paid your utility company in monthly bills.
                </p>
              </div>
              <div>
                <h5>Hedge Against Utility Rate Hikes</h5>
                <p>
                  Secure lower cost, long-term, predictable energy costs thru onsite distributed generation, and protect
                  yourself against the utility’s annual rate hikes.
                </p>
              </div>
            </>
          </SideBySide>
        </Container>
        <Container center>
          <p>Request a free quote today to see how much your property can save with solar!</p>
        </Container>
      </div>
    </Section>
  )
}

export default SaveOnElectricity
