import React from 'react'
import styled from 'styled-components'

import HeaderItem from './HeaderItem'

import Logo from 'marketing/components/Logo/Logo'
import { baseUrl } from 'marketing/helpers/routes'

const HeaderStyled = styled.header({
  display: 'flex',
  justifyContent: 'center',
})

const HeaderMinimal = (): JSX.Element => {
  // In production, these pages live on the `app.` subdomain, but we want the homepage link to redirect to `www.`
  const href = process.env.RAILS_ENV === 'production' ? baseUrl() : '/'

  return (
    <HeaderStyled data-section="header-minimal">
      <HeaderItem href={href}>
        <Logo />
      </HeaderItem>
    </HeaderStyled>
  )
}

export default HeaderMinimal
