import React from 'react'

import Bg1 from './assets/bg1.jpg'
import Bg2 from './assets/bg2.jpg'
import Bg3 from './assets/bg3.jpg'

import Section from 'marketing/components/Section/Section'
import Container from 'marketing/components/Container/Container'
import MediaCard from 'marketing/components/MediaCard/MediaCard'
import MediaCards from 'marketing/components/MediaCard/MediaCards'

const FinancingMediaCards = (): JSX.Element => {
  return (
    <Section
      h2="Unmatched Solar Benefits"
      text="Whether you operate a real estate platform, invest in commercial holdings, or occupy your own buildings, solar can provide many financial and operational benefits. With Wunder’s No-CAPEX offerings and turn-key solutions, deploying solar across your entire portfolio has never been easier or more profitable."
    >
      <div>
        <MediaCards>
          <MediaCard
            title="Unlock NOI"
            subtitle="No CapEx Options"
            text="Solar energy represents a new untapped revenue stream for your real estate holdings."
            background={Bg1}
          />
          <MediaCard
            title="Advance ESG Goals"
            subtitle="Go Green"
            text="Sustainability isn’t just a keyword, it’s now  an investor requirement and a corporate responsibility."
            background={Bg2}
          />
          <MediaCard
            title="Save Money on Power"
            subtitle="Take Control"
            text="Save up to 20% on electricity today, and help hedge against your utility’s annual rate hikes."
            background={Bg3}
          />
        </MediaCards>

        <Container maxWidth={840} center>
          <p>
            Our team can walk you through your options and explain our process step-by-step to help you make an informed
            decision and meet your unique business objectives.
          </p>
        </Container>
      </div>
    </Section>
  )
}

export default FinancingMediaCards
